<template>
    <v-container fluid>
        <base-material-card icon="mdi-account-multiple" title="Clientes" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'ClienteCreate'})">Nuevo</v-btn>
                    <v-btn color="primary" @click="downloadExcel">Excel</v-btn>
                    <v-text-field v-model="pagination.search"
                                  append-icon="mdi-magnify"
                                  label="Búsqueda" single-line hide-details
                                  @keyup="pagination.page = 1; loadDebounce()"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="clientes" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.acciones="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'ClienteEdit',params: {id: item.id}})">
                                        <v-icon>far fa-edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon x-small v-on="on" @click="$router.push({name: 'ClienteSucursal',params: {cliente: item.id, sucursal: item.id}})">
                                        <v-icon>far fa-list-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>Sucursales</span>
                            </v-tooltip>
                        </template>
                      <template v-slot:item.regimen_fiscal="{item}">
                        {{item.regimen_fiscal?item.regimen_fiscal:''}}
                        {{item.regimen?` - ${item.regimen.descripcion}`:''}}
                      </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.page" class="my-4" :length="pagination.lastPage" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    import _ from 'lodash';
    export default {
        data: () => ({
            headers: [
                { text: 'Razón social', value: 'razon_social', sortable: false },
                { text: 'RFC', value: 'rfc', sortable: false },
                { text: 'Codigo Postal', value: 'codigo_postal_domicilio', sortable: false },
                { text: 'Regimen', value: 'regimen_fiscal', sortable: false },
                { text: 'Acciones', value: 'acciones', sortable: false },
            ],
            clientes: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                page: 1,
                search: ""
            }
        }),
        mounted() {
            this.load()
        },
        methods: {
          loadDebounce: _.debounce(function () {
            this.load();
          }, 500),
            load() {
                this.$http.get('/clientes/', {
                    params: this.pagination
                }).then(response => {
                    this.clientes = response.data.data
                    this.pagination.lastPage = response.data.last_page
                    this.pagination.page = response.data.current_page
                })
            },
            downloadExcel() {
                window.location.assign(`/clientes/excel?search=${this.pagination.search}`)
            }
        }
    }
</script>
